import { isImageTransformApplicable } from '../helpers/imageServiceUtils';
import { getImageURI } from '../engines/transforms';
import { getTransform } from './transform';
import type {
  FittingType,
  ImageTransformObject,
  ImageTransformOptions,
  ImageTransformSource,
  ImageTransformTarget,
} from '../types';

/**
 * returns image transform uri
 *
 * @param {string}                  fittingType         imageServicesTypes.fittingTypes
 * @param {ImageTransformSource}    src                 source image
 * @param {ImageTransformTarget}    target              target component
 * @param {ImageTransformOptions}   options             transform options
 * @param {Object}                  [transformObj]      transform data object to use
 *
 * @returns {string}
 */
function getURI(
  fittingType: FittingType,
  src: ImageTransformSource,
  target: ImageTransformTarget,
  options: ImageTransformOptions = {},
  transformObj?: ImageTransformObject,
): string {
  // check if image transformation is applicable (e.g. .gif, .wix_mp)
  if (
    isImageTransformApplicable(
      src.id,
      options?.hasAnimation,
      options?.allowWEBPTransform,
    )
  ) {
    transformObj =
      transformObj || getTransform(fittingType, src, target, options);

    // set the uri property
    return getImageURI(transformObj);
  }

  return src.id;
}

export { getURI };
